// eslint-disable-next-line import/prefer-default-export
import { DAY_MONTH_YEAR } from '@/libs/filterDate'

export const tableBiodata = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'sk_number',
    label: 'No SK',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'published_date',
    label: 'Tanggal Diterbitkan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
    formatter: value => DAY_MONTH_YEAR(value),
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]

export const tableAssignment = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    formatter: (value, key, item) => value,
  },
  {
    key: 'talent_name',
    label: 'Nama Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'partner_name',
    label: 'Nama Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'staff_name',
    label: 'Leader',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'device_name',
    label: 'Device',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'working_space_name',
    label: 'Kantor',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '24px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]

export const tableAssignmentEdit = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'talent_name',
    label: 'Nama Talent',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
  },
  {
    key: 'partner_name',
    label: 'Nama Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'staff_name',
    label: 'Leader',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'device',
    label: 'Device',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'office_name',
    label: 'Kantor',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'action',
    label: 'Aksi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
      paddingRight: '82px !important',
    },
    tdClass: 'text-black',
    class: 'p-1 text-right pr-2',
  },
]
