<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-1">
      Detail Assign Partner
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer ref="formRules">
        <b-row>
          <b-col md="8">
          <!-- form -->
            <b-form>
              <b-row>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      No SK <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="No SK"
                    >
                      <b-form-input
                        v-model="sk"
                        placeholder="Contoh: SK-1291/KM/2023"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      URL <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="URL"
                    >
                      <b-form-input
                        v-model="url"
                        placeholder="Ketikkan URL"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Tanggal Mulai <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Tanggal Mulai"
                      :rules="'required'"
                    >
                      <flat-pickr
                        v-model="startDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'j F Y',
                          dateFormat: 'Y-m-d',
                        }"
                        placeholder="Pilih Tanggal"
                      />
                      <small v-if="!errors[0]">*Tanggal talent mulai dihire oleh partner</small>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <section>
          <validation-observer  ref="formRules">
            <BTable
              :items="talentAssignments"
              :fields="fields"
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              show-empty
            >
              <template #cell(id)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(status)="data">
                <span class="non-job capitalize" v-if="data.item.status === 'non job'">{{ data.item.status }}</span>
                <span class="hired capitalize" v-else>{{ data.item.status }}</span>
              </template>
              <template #cell(action)="data">
                <div class="flex justify-end items-center">
                  <BButton
                    class="btn-icon mr-50"
                    @click="editDevice(data.index, data.item.id)"
                    size="sm"
                    variant="flat-warning"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                      alt="Komerce"
                      class="mr-[5px]"
                    />
                  </BButton>
                  <div @click="data.item.is_unassigned ? false : handleNonjob(data.item.id)">
                    <BFormCheckbox
                      :checked="data.item.is_unassigned ? false : true"
                      switch
                      disabled
                    />
                  </div>
                </div>
              </template>
            </BTable>
          </validation-observer>
        </section>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click="$router.back()"
          >
            Kembali
          </button>
        </div>
      </validation-observer>
    </b-overlay>
    <ModalEdit
      v-if="showEditTalent"
      @close="handleClose()"
      :talentAssignments="assignmentSelected"
      :idSelected="idSelected"
    />
    <ModalNonJob
      :idSelected="idNonjob"
      @updated="getDetailAssignment()"
    />
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import {
  required,
  min,
  minValue,
  max,
  alpha_spaces,
  numeric,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { capitalize } from '@/libs/helpers'
import { tableAssignmentEdit } from './config'
import ModalEdit from './ModalEdit.vue'
import ModalNonJob from './ModalNonJob.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCol,
    BOverlay,
    flatPickr,
    ModalEdit,
    ModalNonJob,
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      capitalize,
      showEditTalent: false,
      assignmentSelected: {},
      idSelected: null,
      idNonjob: null,

      required,
      min,
      max,
      minValue,
      alpha_spaces,
      numeric,

      sk: '',
      url: '',
      startDate: null,

      fields: tableAssignmentEdit,
      talentAssignments: [],
      detailAssignment: {},

      talentExist: [],
      deviceExist: [],

      alertError,
      alertSuccess,
    }
  },
  mounted() {
    this.getDetailAssignment()
  },
  methods: {
    async getDetailAssignment() {
      const url = `v1/partner_assignments/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailAssignment = await data

          this.loadForm()
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    loadForm() {
      this.sk = this.detailAssignment.sk_number
      this.url = this.detailAssignment.url_document
      this.startDate = this.detailAssignment.start_date
      this.talentAssignments = this.detailAssignment.talent_assignment
    },
    editDevice(index, id) {
      this.showEditTalent = true
      this.assignmentSelected = this.talentAssignments[index]
      this.idSelected = id
    },
    handleClose() {
      this.showEditTalent = false
      this.getDetailAssignment()
    },
    handleNonjob(id) {
      this.idNonjob = id
      this.$bvModal.show('modalNonjob')
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.hired {
    background: #DFF3FF;
    border-radius: 4px;
    color: #08A0F7;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 10px;
    width: 100%;
}
.non-job {
    background: #F4F4F4;
    border-radius: 4px;
    color: #C2C2C2;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 10px;
    width: 100%;
}
</style>
